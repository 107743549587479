import { useState } from "react";
import { Button, Typography } from "awantunai-design-system";
import TablePage from "../../components/site/TablePage";
import AddSite from "../../components/site/AddSite";
import { styled } from "styled-components";
import { observer } from "mobx-react";
import Loading from "components/general/Loading";
import MainStore from "store/MainStore";

const SiteList = () => {
    const loading = MainStore.loading;
    const [drawer, setDrawer] = useState<boolean>(false);
    const [edit, setEdit] = useState<boolean>(false);
    const [siteInfo, setSiteInfo] = useState<any>("");
    const onDrawerClose = (value: boolean) => {
        setDrawer(value);
        if (!value) {
          setEdit(false);
        }
    };

    const onChangeEdit = (value: boolean) => {
        setEdit(value);
    };

    const onSetSiteCode = (value: any) => {
      setSiteInfo(value);
    }

    return (
        <Container>
            <div className="site">
                <Typography variant="h1">List of sites</Typography>
                <Button
                    title="Add"
                    onClick={() => {
                        setEdit(false);
                        setDrawer(true);
                        setSiteInfo("");
                    }}
                    size="small"
                    icon="AddOne"
                    variant="secondary"
                    style={{
                        width: 100,
                        margin: 0,
                        display: "flex",
                    }}
                />
            </div>
            <div className={loading ? "none" : "display"}>
                <TablePage
                    onSetSiteCode={onSetSiteCode}
                    onChangeEdit={onChangeEdit}
                    onDrawerClose={onDrawerClose}
                />
            </div>
            <Loading isloading={loading} />
            <AddSite
                edit={edit}
                siteInfo={siteInfo}
                onDrawerClose={onDrawerClose}
                isOpen={drawer}
            />
        </Container>
    );
};

export default observer(SiteList);

const Container = styled.div`
    .site {
        flex-direction: row;
        display: flex;
        justify-content: space-between;
        margin: 40px 40px 0px;
    }
    .circle {
        left: 50%;
        position: absolute;
        top: 50%;
        transform: translate(5.125rem);
    }
    .display {
        display: block;
    }
    .none {
        display: none;
    }
`;
