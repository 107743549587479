
import { Typography } from 'awantunai-design-system';

const ForgotPasswordView = () => {

    return (
        <div>
            <Typography variant="h1">ForgotPasswordView</Typography>
        </div>
    )

}

export default ForgotPasswordView;