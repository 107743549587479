import { action, makeObservable, observable } from "mobx";



interface IAlert {
    title: string;
    message: string;
    width?: number;
    cancelText?: string;
    confirmText?: string;
    onCancel?: () => void;
    onConfirm?: () => void;
}

class Alert {


    title: string = '';
    message: string = '';
    width: number = 400;
    cancelText: string = 'Cancel';
    confirmText: string = 'Confirm';
    isOpen: boolean = false;
    onCancel?: () => void;
    onConfirm?: () => void;

    constructor() {
        makeObservable(this, {
            title: observable,
            message: observable,
            width: observable,
            cancelText: observable,
            confirmText: observable,
            isOpen: observable,

            show: action,
            hide: action
        });
    }

    show({
        title,
        message,
        width = 400,
        cancelText = 'Cancel',
        confirmText = 'Confirm',
        onCancel,
        onConfirm
    }: IAlert) {
        this.title = title;
        this.message = message;
        this.width = width;
        this.cancelText = cancelText;
        this.confirmText = confirmText;
        this.onCancel = onCancel;
        this.onConfirm = onConfirm;
        this.isOpen = true;
    }

    hide() {
        this.isOpen = false;
    }

    toast(message: string) {
        
    }
}

const AlertStore = new Alert();

export default AlertStore;