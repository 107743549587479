import {
    InputBox,
    ThemeColors,
    Button,
    Dropdown,
    CheckBox
} from "awantunai-design-system";
import { IHomeMenu, IOnChangeEvent } from "interfaces";
import React, { useState } from "react";
import { styled } from "styled-components";
import FirebaseApi from "services/FirebaseApi";
import RadioButton from "../general/RadioButton";
import UploadImagePicker from "components/general/UploadImagePicker";
import { screen_datas } from "../../constants";
import { useParams } from "react-router";
interface HomeMenuItemProps {
    data: IHomeMenu;
    id: number;
    onChange: (value: IOnChangeEvent) => void;
    onDelete: () => void;
}

const HomeMenuItem = (props: HomeMenuItemProps) => {

    const [uploading, setUploading] = useState<boolean>(false);
    const [radioValue, setRadioValue] = useState<string>('');
    const params = useParams();
    const handleChangeFile = async (file: any) => {
        if (file === null) {
            props.onChange({
                target: {
                    name: 'image'
                }
            } as any)
            return;
        }
        setUploading(true);
        let url = await FirebaseApi.upload(file, null, (e) => {
            if (e) {
                setUploading(true);
            }
        });
        setUploading(false)
        props.onChange({
            target: {
                name: 'image',
                value: url
            }
        })
    }

    const handleChangeRadio = (option: any) => {
        props.onChange({
            target: {
                name: 'type',
                value: option.value
            }
        });
        setRadioValue(option.value);
    }
    const handleChangeLink = (e: any) => {
        props.onChange({
            target: {
                name: 'link',
                value: e
            }
        })
    }

    const screens = () => {
        if (params.companyId === '00003') {// navigator
            return [
                ...screen_datas,
                { value: 'Navigator', label: 'Navigator' },
                { value: 'NavigatorOSB', label: 'NavigatorOSB' }
            ]
        }
        return screen_datas;
    }

    return (
        <Container>
            <div className="delete-layout">
                <Button
                    icon="Delete"
                    variant="secondary"
                    size="small"
                    onClick={props.onDelete}
                />
            </div>
            <div className="input-layout">
                <InputBox
                    label="Title"
                    name='name'
                    value={props.data.name}
                    onChange={props.onChange}
                />
            </div>
            <UploadImagePicker
                label="Image"
                url={props.data.image}
                onChange={(file: any) => {
                    handleChangeFile(file)
                }}
                uploading={uploading}
                style={{ height: 160, width: 250 }}
            />
            <div className="radio-layout">
                <RadioButton options={[{ value: 'internal', label: "App Screen" }, { value: 'external', label: "Web View" }]}
                    value={props.data.type} direction="row" onChange={handleChangeRadio}
                />
            </div>
            <div className="input-layout">
                {(props.data.type === 'internal' || radioValue === 'internal') ?
                    <Dropdown
                        label="Link"
                        value={props.data.link}
                        onChange={(value: any) => {
                            handleChangeLink(value);
                        }}
                        options={screens()}
                        placeholder="Select Type"
                    /> :
                    <InputBox
                        label="Link"
                        name='link'
                        value={props.data.link || ''}
                        onChange={props.onChange}
                    />
                }
            </div>
            <div className="input-layout">
                <InputBox
                    label="Description"
                    status="normal"
                    name='description'
                    value={props.data.description}
                    onChange={(e) => props.onChange({ target: { name: 'description', value: e.target.value } })}
                    variant="textarea"
                />
            </div>
            <div className="input-layout">
                <CheckBox
                    checked={props.data.hidden ? 1 : 0}
                    label="Hide"
                    // @ts-ignore
                    onClick={() => props.onChange({ target: { name: 'hidden', value: !props.data.hidden } })}
                />
            </div>
        </Container>
    );
};

export default HomeMenuItem;

const Container = styled.div`
    display: inline-flex;
    flex-direction: column;
    position: relative;
    background-color: ${ThemeColors["secondary-light"]};
    border-radius: 8px;
    padding: 20px 10px;
    margin: 10px;
    box-sizing: border-box;
    .radio-layout {
        padding-top: 15px;
    }
    .input-layout {
        margin: 10px;
    }
    .delete-layout {
        position: absolute;
        top: 0px;
        right: 0px;
        padding: 5px;
        button {
            border: none;
        }
    }
`;