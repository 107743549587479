import { storage } from "../helpers/firebase_config";
import firebase from 'firebase';
import { v4 } from 'uuid';



class FirebaseApi {
  async fileUpload (file :any) {
    const path = `/au-assist/files/${file.name}`;
    const ref = storage.ref(path);
    await ref.put(file);
    const url = await ref.getDownloadURL();
    return url;
  };

  upload(file: File, bucket: string | null,
    cb?: (snapShot: firebase.storage.UploadTaskSnapshot) => void): Promise<string> {
      if(!bucket) bucket='/au-assist/files/'
      const extension = file.name.split(".").pop();
      const filename = v4() + "." + extension;
      const reference = storage.ref(`/${bucket}/${filename}`);
      const task = reference.put(file);
      return new Promise((resolve:any, reject: any) => {
        task.on("state_changed", cb);
        task.then(() => {
          const url = reference.getDownloadURL();
          resolve(url);
        }).catch(reject);
      })
    }
    
}

const firebaseApi = new FirebaseApi();
export default firebaseApi;
