import {
  InputBox,
  ThemeColors,
  Button,
  Dropdown,
} from "awantunai-design-system";
import React, { useState } from "react";
import { ILeftMenu, IOnChangeEvent } from "interfaces";
import { styled } from "styled-components";
import RadioButton from "../general/RadioButton";
import { screen_datas } from "../../constants";
import { useParams } from "react-router";

interface LeftMenuItemProps {
  data: ILeftMenu;
  id: number;
  onChange: (value: IOnChangeEvent) => void;
  onDelete: () => void;
}

const LeftMenuItem = (props: LeftMenuItemProps) => {
  const [radioValue, setRadioValue] = useState<string>('');
  const params = useParams();

  const handleChangeRadio = (option: any) => {
    props.onChange({
      target: {
        name: 'type',
        value: option.value
      }
    });
    setRadioValue(option.value);
  }

  const handleChangeLink = (e: any) => {
    props.onChange({
      target: {
        name: 'link',
        value: e
      }
    })
  }

  const screens = () => {
    if (params.companyId === '00003') {// navigator
      return [
        ...screen_datas,
        { value: 'Navigator', label: 'Navigator' },
        { value: 'NavigatorOSB', label: 'NavigatorOSB' }
      ]
    }
    return screen_datas;
  }

  return (
    <Container>
      <div className="left">
        <div className="delete-layout">
          <Button
            icon="Delete"
            variant="secondary"
            size="small"
            onClick={props.onDelete}
          />
        </div>
        <div className="input-layout">
          <InputBox
            label="Title"
            name='name'
            value={props.data.name}
            onChange={props.onChange}
          />
        </div>
        <div className="input-layout">
          <InputBox
            label="Icon"
            name="icon"
            value={props.data.icon}
            onChange={props.onChange}
          />
        </div>
        <div className="radio-layout">
          <RadioButton options={[{ value: 'internal', label: "App Screen" }, { value: 'external', label: "Web View" }]}
            value={props.data.type} direction="row" onChange={handleChangeRadio}
          />
        </div>
        <div className="input-layout">
          {(props.data.type === 'internal' || radioValue === 'internal') ?
            <Dropdown
              label="Link"
              value={props.data.link}
              onChange={(value: any) => {
                handleChangeLink(value);
              }}
              options={screens()}
              placeholder="Select Type"
            /> :
            <InputBox
              label="Link"
              name='link'
              value={props.data.link || ''}
              onChange={props.onChange}
            />
          }
        </div>
        <div className="input-layout">
          <InputBox
            label="Description"
            status="normal"
            name='description'
            value={props.data.description}
            onChange={props.onChange}
            className="mt-3"
            variant="textarea"
          />
        </div>
      </div>
    </Container>
  );
};

export default LeftMenuItem;

const Container = styled.div`
    display: inline-flex;
    position: relative;
    flex-direction: column;
    background-color: ${ThemeColors["secondary-light"]};
    border-radius: 8px;
    padding: 10px;
    margin: 20px 10px;
    box-sizing: border-box;

    .radio-layout {
        padding-top: 15px;
    }

    .input-layout {
        margin: 10px;
    }

    .delete-layout {
        position: absolute;
        top: 0px;
        right: 0px;
        padding: 5px;
        button {
            border: none;
        }
    }
`;
