import { BaseModal, InputBox } from 'awantunai-design-system';
import { useState } from 'react';
import { useParams } from 'react-router';
import mainStore from 'store/MainStore';
import styled from 'styled-components';


interface IProps {
    visible: boolean;
    onClose: () => void;
}

const AddIntegrationModal = (props: IProps) => {

    const { companyId, siteCode } = useParams();
    const [key, setKey] = useState("");
    const [name, setName] = useState("");

    const onAdd = () => {
        if (key === "" || name === "") {
            return;
        }

        mainStore.addIntegration(companyId || "", siteCode || "", {
            'enabled': true,
            'extra': [],
            key,
            'serviceName': name
        })
        props.onClose();
    }

    const onChangeName = (e: any) => {
        setName(e.target.value);
    }

    const onChangeKey = (e: any) => {
        setKey(e.target.value);
    }

    return (
        <BaseModal
            buttons={[
                {
                    disabled: false,
                    onClick: props.onClose,
                    title: 'Cancel'
                },
                {
                    disabled: false,
                    onClick: onAdd,
                    title: 'Add'
                }
            ]}
            isOpen={props.visible}
            onClose={props.onClose}
            size={650}
            title="Add New Integration"
        >
            <Container>
                <InputBox
                    label='Service Name'
                    name='name'
                    value={name}
                    onChange={onChangeName}
                />
                <InputBox
                    label='Service Key'
                    name='key'
                    value={key}
                    onChange={onChangeKey}
                />
            </Container>
        </BaseModal>
    )
}

export default AddIntegrationModal;


const Container = styled.div`
    display: flex;
    flex-direction: column;
    padding: 20px;
    gap: 20px;
`