import { Button, Typography } from "awantunai-design-system";
import LeftMenuItem from "components/menu/LeftMenuItem";
import { ILeftMenu } from "interfaces";
import MainStore from "store/MainStore";
import { runInAction, toJS } from "mobx";
import { observer } from 'mobx-react';
import { useParams } from "react-router";
import { Container } from '../../components/styleComponent/Container';
import Loading from "components/general/Loading";
import { ListManager } from "react-beautiful-dnd-grid";

const LeftMenuView = () => {
    const { companyId, siteCode } = useParams();
    const loading = MainStore.loading;

    const onSave = () => {
        if (companyId && siteCode) {
            MainStore.updateMenus({
                homeMenu: toJS(MainStore.menus.homeMenu),
                leftMenu: toJS(MainStore.menus.leftMenu)
                    .filter((menu: any) => menu.name !== "" && menu.link !== "")
            }, companyId, siteCode);
        }
    };

    const onChangeMenu = (e: any, index: number) => {
        MainStore.menus.leftMenu[index] = {
            ...MainStore.menus.leftMenu[index],
            [e.target.name]: e.target.value
        }
    };

    const onDelete = (index: number) => {
        const newMenus = [...MainStore.menus.leftMenu];
        let filterResult = newMenus.filter((menu, i) => i !== index);
        MainStore.menus.leftMenu = filterResult;
    };

    const onAdd = () => {
        const newMenus = [...MainStore.menus.leftMenu];
        newMenus.push({
            name: "",
            link: "",
            type: "external",
            description: "",
            icon: ""
        });
        runInAction(() => {
            MainStore.menus.leftMenu = newMenus;
        });
    };

    const onExportFile = () => {
        const data = JSON.stringify(MainStore.menus.leftMenu);
        const blob = new Blob([data], { type: "application/json" });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.setAttribute("href", url);
        a.setAttribute("download", `${companyId}_${siteCode}_leftMenu.json`);
        a.click();
    }

    const onImportFile = () => {
        const input = document.createElement("input");
        input.type = "file";
        input.accept = ".json";
        input.onchange = async (e: any) => {
            const file = e.target.files[0];
            if (file) {
                const text = await file.text();
                const data = JSON.parse(text);
                MainStore.menus.leftMenu = data;
            }
        }
        input.click();
    }

    const reorderList = (sourceIndex: number, destinationIndex: number) => {
        if (sourceIndex === destinationIndex) return
        const sourceItem = MainStore.menus.leftMenu[sourceIndex]
        MainStore.menus.leftMenu.splice(sourceIndex, 1)
        MainStore.menus.leftMenu.splice(destinationIndex, 0, sourceItem)
    }

    return (
        <Container>
            <Typography variant="h4" mb={15}>Left menus</Typography>
            <div className="scroll-view">
                <div className={"section"}>
                    {MainStore.menus.leftMenu ?
                        <ListManager
                            items={MainStore.menus.leftMenu.map((_menu: ILeftMenu, index) => index)}
                            direction="horizontal"
                            maxItems={4}
                            render={(index) => <LeftMenuItem
                                key={`${index}`}
                                data={MainStore.menus.leftMenu[index]}
                                id={index}
                                onChange={(m: any) => onChangeMenu(m, index)}
                                onDelete={() => onDelete(index)}
                            />}
                            onDragEnd={reorderList}
                        />
                        :
                        <>No datas</>
                    }
                    <Button
                        title="Add"
                        onClick={onAdd}
                        size="small"
                        icon="AddOne"
                        variant="secondary"
                        style={{ width: 100, margin: 10 }}
                    />
                </div>
            </div>
            <Loading isloading={loading} />
            <div>
                <Button
                    title="Save"
                    onClick={onSave}
                    size="small"
                    icon="SaveOne"
                    variant="primary"
                    style={loading ? { display: 'none' } : { width: 100, marginLeft: 20, marginTop: 20, display: "inline-flex" }}
                />
                <Button
                    title="Import File"
                    onClick={onImportFile}
                    size="small"
                    icon="Upload"
                    variant="primary"
                    style={loading ? { display: 'none' } : { width: 120, marginLeft: 20, marginTop: 20, display: "inline-flex" }}
                />
                <Button
                    title="Export File"
                    onClick={onExportFile}
                    size="small"
                    icon="Download"
                    variant="primary"
                    style={loading ? { display: 'none' } : { width: 120, marginLeft: 20, marginTop: 20, display: "inline-flex" }}
                />
            </div>
        </Container>
    );
};

export default observer(LeftMenuView);
