import { Outlet } from "react-router";
import { styled } from "styled-components";

const AuthLayout = () => {

    return (
        <Container>
            <Outlet />
        </Container>
    )
}

export default AuthLayout;


const Container = styled.div`
    width: 100%;
    height: 100%;
    background-color: #1d2d59;
`;