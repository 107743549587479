import { styled } from "styled-components";
import { Typography, InputBox, Dropdown, BaseModal } from 'awantunai-design-system';
import React, { useState } from "react";
import { runInAction } from "mobx";
import mainStore from "store/MainStore";
import { observer } from 'mobx-react';

interface PropsType {
    id: number;
    onClick:() => void;
    isOpen: boolean;
}

const input_type = [
        {
            label: 'String',
            value: 'string'
        },
        {
            label: 'Bool',
            value: 'bool'
        },
        {
            label: 'File',
            value: 'file'
        }
]

const AddIntegrationModal = (props: PropsType) => {
    const [errorAlert, setErrorAlert] = useState({ inputname: '', inputtype: '' });
    const [inputname, setInputname] = useState<string>('');
    const [inputtype, setInputtype] = useState<string>('');

    const updateErrorMessage = (key: string, value: string) => {
        setErrorAlert((prev) => {
            return { ...prev, [key]: value };
        });
    };

    const subAdd = () => {
        if (!inputname) {
            updateErrorMessage('inputname', 'Required input value!')
        } else if (!inputtype) {
            updateErrorMessage('inputtype', 'Required input value!')
        } else {
            const addData = [...mainStore.integrations[props.id].extra];
            if (addData) {
                let validInputTypes: "string" | "bool" | "file" = "string";
                if (inputtype === "string" || inputtype === "bool" || inputtype === "file") {
                    validInputTypes = inputtype;
                }
                addData.push({
                    key: inputname,
                    value: "",
                    inputType: validInputTypes
                });
            }
            props.onClick();
            setErrorAlert({ inputname: '', inputtype: '' });
            setInputname('');
            setInputtype('');
            runInAction(() => {
                mainStore.integrations[props.id].extra = addData;
            });
        }
    }

    return (
            <BaseModal
                buttons={[
                    {
                        disabled: false,
                        onClick: props.onClick,
                        title: 'Cancel'
                    },
                    {
                        disabled: false,
                        onClick: () => subAdd(),
                        title: 'Add'
                    }
                ]}
                isOpen = {props.isOpen}
                onClose={props.onClick}
                size={650}
                title="InputType Set Modal"
            >
                <Container>
                    <InputBox
                        label='Name'
                        name='name'
                        value={inputname}
                        helpText={errorAlert.inputname}
                        status={errorAlert.inputname !== '' ? 'error' : 'normal'}
                        onChange={(e) => {
                            setInputname(e.target.value);
                            updateErrorMessage(e.target.name, '');
                        }}
                    />
                    <Dropdown
                        label="InputType"
                        value={inputtype}
                        onChange={(value:any) => {
                            setInputtype(value);
                            updateErrorMessage(value, '');
                        }}
                        options={input_type}
                        placeholder="Select Type"
                    />
                    {errorAlert.inputtype !== '' ?
                        <Typography
                            color="error-main"
                            variant="body3"
                            style={{marginLeft:'20px'}}
                        >
                            {errorAlert.inputtype}
                        </Typography> : (<></>)
                    }
                </Container>
            </BaseModal>
    )
}

export default observer(AddIntegrationModal);

const Container = styled.div` 
    padding: 20px 35px;
    max-width:40%;
    display: flex;
    flex-direction: column-reverse;
    row-gap: 15px;
`