import React, { useState } from "react";
import { styled } from "styled-components";
import { Typography, Icons, ThemeColors, } from 'awantunai-design-system';

interface AccordionProps {
    label: string;
    children?: any;
    style?: any;
}

const Accordion = (props: AccordionProps) => {
    const [show, setShow] = useState<boolean>(false);

    const onToggle = () => {
        setShow(!show);
    }

    const Icon = Icons[show ? 'Up' : 'Down'];

    return (
        <Container style={props.style}>
            <div className="header" onClick={onToggle}>
                <Typography variant="subtitle3" color='secondary-light'>{props.label}</Typography>
                <Icon
                    fill={[ThemeColors['secondary-light']]}
                    size={24}
                    style={{ height: 24 }}
                />
            </div>
            {show ? <div className="content">
                {props.children}
            </div> : null}
        </Container>
    )
}

export default Accordion;

const Container = styled.div`
    margin-bottom: 10px;
    border-radius: 8px;
    overflow: hidden;

    .header {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        background-color: ${ThemeColors['success-main']};
        cursor: pointer;
        height: 40px;
        text-align: center;
        padding: 0px 10px;
    }
    & > .content {
        padding: 20px;
        background-color: white;
    }
`;