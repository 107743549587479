import React, { useState } from "react";
import { Button, Typography } from "awantunai-design-system";
import ColorPicker from "components/theme/ColorPicker";
import { observer } from "mobx-react";
import MainStore, { AssetKeys, ColorKeys } from "store/MainStore";
import { runInAction, toJS } from "mobx";
import UploadImagePicker from "components/general/UploadImagePicker";
import FirebaseApi from "services/FirebaseApi";
import { useParams } from "react-router";
import Loading from "components/general/Loading";
import { Container } from '../../components/styleComponent/Container';
import UploadVideoPicker from "components/general/UploadVideoPicker";
import UploadFilePicker from "components/general/UploadFilePicker";

const defaultColor = {
    background: "",
    green: "",
    grey: "",
    primary: "",
    secondary: "",
    text: "",
    white: ""
}

const defaultAssets = {
    homeImage: "",
    loadingLottie: "",
    logo: "",
    menuBack: ""
}
const ThemeView = () => {
    const colors = MainStore.theme?.colors as any ?? defaultColor as any;
    const assets = MainStore.theme?.assets as any ?? defaultAssets as any;
    const { companyId, siteCode } = useParams();
    const [uploadingIndex, setUploadingIndex] = useState<number>(-1);
    const loading = MainStore.loading;

    const updateTheme = (key: string, value: string) => {
        runInAction(() => {
            if (MainStore.theme.colors && Object.keys(MainStore.theme.colors).length > 0) {
                MainStore.theme.colors[key] = value;
            } else {
                //MainStore.theme.colors = {} as { [id: string]: string };
                MainStore.theme.colors = ({ key: value });
            }

        });
    };

    const updateAssets = async (key: string, file: any, index: number) => {
        if (file === null){
            MainStore.theme.assets[key] = null as any;
            return;
        }

        setUploadingIndex(index);
        try {
            let url = await FirebaseApi.upload(file, null, () => { });
            runInAction(() => {
                if (MainStore.theme.assets && Object.keys(MainStore.theme.assets).length > 0) {
                    MainStore.theme.assets[key] = url;
                } else {
                    MainStore.theme.assets = {} as { [id: string]: string };
                    MainStore.theme.assets = ({ key: url });
                }
            });
        } catch (e) {
            console.log(e);
        }
        setUploadingIndex(-1);
    };

    const onSave = () => {
        if (companyId && siteCode) {
            MainStore.updateTheme(toJS(MainStore.theme), companyId, siteCode);
        }
    };

    const onExportFile = () => {
        const data = JSON.stringify(MainStore.theme);
        const blob = new Blob([data], {type: "application/json"});
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.setAttribute("href", url);
        a.setAttribute("download", `${companyId}_${siteCode}_appearance.json`);
        a.click();
    }

    const onImportFile = () => {
        const input = document.createElement("input");
        input.type = "file";
        input.accept = ".json";
        input.onchange = async (e: any) => {
            const file = e.target.files[0];
            if (file) {
                const text = await file.text();
                const data = JSON.parse(text);
                MainStore.theme = data;
            }
        }
        input.click();
    }

    return (
        <Container>
            <Typography variant="h4" mb={15}>Theme Colors</Typography>
            <div className="scroll-view">
                <div className={"section"}>
                    {ColorKeys.map((key, index) => (
                        <ColorPicker
                            key={index}
                            label={key.name}
                            color={colors[key.id]}
                            onChange={(color: string) => updateTheme(key.id, color)}
                        />
                    ))}
                </div>

                <Typography variant="h4" display="block" mt={20}>
                    Assets
                    <Typography variant="body3" color="secondary-dark" ml={10}>
                        (You can drag and drop a file)
                    </Typography>
                </Typography>
                <div className={"section"}>
                    {AssetKeys.map((item, index) => (
                        item.type === 'image' ? <UploadImagePicker
                            key={index}
                            label={item.name}
                            url={assets[item.id]}
                            uploading={index === uploadingIndex}
                            onChange={(url: string) => updateAssets(item.id, url, index)}
                            style={{ width: 240, height: 160 }}
                        /> : item.type === 'video' ? <UploadVideoPicker
                            key={index}
                            label={item.name}
                            url={assets[item.id]}
                            uploading={index === uploadingIndex}
                            onChange={(url: string) => updateAssets(item.id, url, index)}
                            style={{ width: 240, height: 160 }}
                        /> : <UploadFilePicker
                            key={index}
                            label={item.name}
                            url={assets[item.id]}
                            uploading={index === uploadingIndex}
                            types={['json', 'mp4']}
                            helpText="Only accept json and mp4 file"
                            onChange={(url: string) => updateAssets(item.id, url, index)}
                            style={{ width: 240, height: 160 }}
                        />
                    ))}
                </div>
                <Loading isloading={loading} />
            </div>
            <div>
                <Button
                    title="Save"
                    onClick={onSave}
                    size="small"
                    icon="SaveOne"
                    variant="primary"
                    style={loading ? { display: 'none' } : { width: 100, marginLeft: 20, marginTop: 20, display: "inline-flex" }}
                />
                <Button
                    title="Import File"
                    onClick={onImportFile}
                    size="small"
                    icon="Upload"
                    variant="primary"
                    style={loading ? { display: 'none' } : { width: 120, marginLeft: 20, marginTop: 20, display: "inline-flex" }}
                />
                <Button
                    title="Export File"
                    onClick={onExportFile}
                    size="small"
                    icon="Download"
                    variant="primary"
                    style={loading ? { display: 'none' } : { width: 120, marginLeft: 20, marginTop: 20, display: "inline-flex" }}
                />
            </div>
        </Container>
    );
};

export default observer(ThemeView);
