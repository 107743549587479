import { styled } from "styled-components";
import { DragDrop } from "awantunai-design-system";
interface UploadProps {
    onChange: (url: string) => void;
    url?: string;
    label: string;
    helpText?: string;
    uploading?: boolean;
    style?: any;
}

const UploadVideoPicker = ({
    url,
    label,
    helpText,
    onChange,
    uploading,
    style
}: UploadProps) => {
    return (
        <Container>
            <DragDrop
                helpText={helpText || "Max size 20MB | MP4, MOV, AVI"}
                label={label}
                maxSize={20}
                minSize={0}
                onChange={(file: any) => {
                    onChange(file);
                }}
                value={url}
                placeholder="Drag & drop a video here"
                types={['mp4', 'mov', 'avi']}
                style={style}
                loading={uploading}
            >
                {url && <video
                    src={url}
                    controls
                    style={{ width: '100%', height: '100%', objectFit: 'contain' }}
                />}
            </DragDrop>
        </Container>
    )
}

export default UploadVideoPicker;

const Container = styled.div`
    display: inline-block;
    margin: 10px;
`;