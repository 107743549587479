import styled from "styled-components";
import { ThemeColors, Button, Icons, Typography } from "awantunai-design-system";
import { IOnboard } from "interfaces";
import { useState } from "react";

interface IProps {
    onEdit: (card: any) => void;
    onRemove: (card:any)=>void;
    onChange: (card:any)=>void;
    onboarding: IOnboard;
}

const OnboardingCard = (props:IProps) => {     
    const IconDelete = Icons['DeleteFive'];
    const IconEdit = Icons['Edit'];
    const [visible] =useState(props.onboarding.visible);

    return (    
        <CardContainer>
            <EditButton>
                <IconEdit 
                    theme="two-tone"
                    fill={[ThemeColors['primary-main'], 'transparnet']}
                    size={24}
                    style={{ height:24 }}
                    onClick={()=>props.onEdit(props.onboarding)}
                />
            </EditButton>
            <RemoveIcon>
                <IconDelete 
                    theme="two-tone"
                    fill={[ThemeColors['error-dark'], 'transparnet']}
                    size={24}
                    style={{ height:24 }}
                    onClick={()=>props.onRemove(props.onboarding)}
                />
            </RemoveIcon>    
            <DescBox>
                <Typography
                    decoration="none"
                    variant="subtitle2"
                    mb={10}
                    style={{textAlign:'center'}}
                    >{props.onboarding.title}
                </Typography>
                <Typography
                    decoration="none"
                    variant="body3"
                    style={{textAlign:'center'}}
                    >{props.onboarding.description}
                </Typography>
            </DescBox>              
            <CardImg src={props.onboarding.image}/>
            <Button
                size="small"
                style={{
                    width:30, 
                    height:30, 
                    borderRadius: 20, 
                    backgroundColor: visible?'#52dfbd':'#d9d9d8', 
                    position: "absolute", 
                    bottom: 20,
                    boxShadow: '0 2px 6px 0 rgba(116,120,141,1.5)'}}
            />
        </CardContainer>
    )
};

export default OnboardingCard;

const CardContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 230px;
    height: 360px;
    border-radius: 20px;
    border-color: ${ThemeColors["primary-dark"]};
    border: solid;
    border-width: 1px;
    position: relative;
    background: white;
    overflow: hidden;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    padding: 20px;
    margin-left: 10px;
    margin-top: 10px;
`

const EditButton = styled.div`
    width: 24px;
    height: 24px;
    color: white;
    background-color: ${ThemeColors.main};
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    margin: 2px;
    cursor: pointer;
    display: flex;
    font-size: 14px;
    position: absolute;
    top: 10px;
    left: 10px;
`

const RemoveIcon = styled.div`
    position: absolute;
    top: 10px;
    right: 10px;
`

const DescBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 120px;
    margin-top:20px;
`
const CardImg = styled.img`
    width: 100%;
    height: 160px;
    border-radius: 20px;
    object-fit: cover;
    margin-top: 20px;
`
