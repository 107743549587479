import { ThemeColors, Typography } from "awantunai-design-system";
import { styled } from "styled-components";
import { ChromePicker } from 'react-color'
import { useEffect, useState } from "react";

interface ColorPickerProps {
    onChange: (color: string) => void;
    color: string;
    label: string;
}

const ColorPicker = (props: ColorPickerProps) => {

    const [showPicker, setShowPicker] = useState(false);

    const onColorClick = () => {
        setShowPicker(true);
    }

    const onChangeColor = (color: any) => {
        props.onChange(color.hex);
    }

    const onPickColor = (color: any) => {
        setShowPicker(false);
    }

    useEffect(() => {
        window.addEventListener('click', (e: any) => {
            if (!e.target.closest('.color-picker')) {
                setShowPicker(false);
            }
        });
        return () => {
            window.removeEventListener('click', onPickColor);
        }
    }, [])

    return (
        <Container className="color-picker">
            <Typography variant="body3" color='secondary-dark' ml={10}>{props.label}</Typography>
            <ColorButton onClick={onColorClick} color={props.color}>
                <div className="color-cell" />
                <Typography variant="caption" color='secondary-light'>{props.color}</Typography>
            </ColorButton>
            {showPicker ? <PickerPopover>
                <ChromePicker
                    color={props.color}
                    onChange={onChangeColor}
                />
            </PickerPopover> : null}
        </Container>
    )

}

export default ColorPicker;

const Container = styled.div`
    position: relative;
    display: inline-flex;
    flex-direction: row;
    height: 36px;
    width: 200px;
    box-sizing: border-box;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    gap: 10px;
    background-color: ${ThemeColors['info-main']};
    border-radius: 8px;
    margin: 10px;
`;

const ColorButton = styled.div`
    height: 100%;
    background-color: ${ThemeColors['success-dark']};
    border-radius: 8px;
    display: flex;
    align-items: center;
    padding: 0px 10px;

    .color-cell {   
        width: 20px;
        height: 20px;
        border-radius: 4px;
        border: 1px solid ${ThemeColors['secondary-light']};
        background-color: ${(props: any) => props.color};
        margin-right: 10px;
    }
`;

const PickerPopover = styled.div`
    position: absolute;
    z-index: 2;
    top: 40px;
`;
