import { useEffect, useState } from "react";
import { styled } from "styled-components";
import {
    Typography,
    InputBox,
    Drawer,
    Button,
    CheckBox,
    ThemeColors,
} from "awantunai-design-system";
import FirebaseApi from "services/FirebaseApi";
import { observer } from "mobx-react";
import UploadVideoPicker from "components/general/UploadVideoPicker";
import UploadImagePicker from "components/general/UploadImagePicker";
import { useParams } from "react-router";
import mainStore from "store/MainStore";
import {
    validateEmail,
    validateSiteCode,
    validatePassword,
} from "../../constants";
import AlertStore from "store/AlertStore";

interface PropsType {
    edit: boolean;
    siteInfo: FormType;
    onDrawerClose: any;
    isOpen: boolean;
}

export const Directories = [
    { id: "buy", name: "Buy" },
    { id: "sell_my_car", name: "Sell My Car" },
    { id: "insurance", name: "Insurance" },
    { id: "rental", name: "Rental" },
    { id: "tyres", name: "Tyres" },
    { id: "parts", name: "Parts" },
    { id: "parking", name: "Parking" },
    { id: "fix_my_car", name: "Fix My Car" },
    { id: "charge_points", name: "Charge Points" },
    { id: "my_carbon", name: "My Carbon" },
    { id: "games", name: "Games" },
    { id: "finance", name: "Finance" },
    { id: "car_warranty", name: "Car Warranty" },
    { id: "gas_insurance", name: "Gas Insurance" },
    { id: "service_plan", name: "Service Plan" },
    { id: "protect", name: "Protect" },
];

interface DirectoriesType {
    [id: string]: boolean;
}

interface FormType {
    companyCode: string | undefined;
    logo: string;
    home_video_url: string;
    code: number | "";
    name: string;
    phone: string;
    email: string;
    password?: string;
    address: string;
    postcode: string;
    websiteUrl: string;
    bookingUrl: string;
    tutorialVideo: string;
    location: {
        lat: string;
        lon: string;
    };
    wasDealerId: string;
    managerName: string;
    spincarId: string;
    color: string;
    services: DirectoriesType;
    id?: string;
}

const AddSite = (props: PropsType) => {
    const { id } = useParams();
    const loading = mainStore.loading;

    const [formData, setFormData] = useState<FormType>();

    useEffect(() => {
        if (props.edit === true) {
            setFormData(props.siteInfo);
        } else {
            // @ts-ignore
            setFormData({ companyCode: id });
        }
    }, [id, props]);

    const [errorAlert, setErrorAlert] = useState({
        code: "",
        name: "",
        email: "",
        password: "",
        address: "",
        phone: "",
        postcode: "",
    });

    const onAdd = async () => {
        if (!formData) return;
        if (!id) {
            updateErrorMessage("code", "Invalid companyCode");
        }
        if (!validateSiteCode(formData.code)) {
            updateErrorMessage("code", "Must be 7 digits");
        }
        if (!formData.password) {
            updateErrorMessage("password", "Invalid password");
        }
        if (!validatePassword(formData.password)) {
            updateErrorMessage(
                "password",
                "It must contain uppercase letters, numbers, and special characters (at least 8 digits)."
            );
        }
        if (!formData.phone) {
            updateErrorMessage("phone", "Invalid phone");
        }
        if (!formData.address) {
            updateErrorMessage("address", "Invalid address");
        }
        if (!formData.email) {
            updateErrorMessage("email", "Invalid email");
        }
        if (!validateEmail(formData.email)) {
            updateErrorMessage("email", "Not email format");
        }
        if (!formData.postcode) {
            updateErrorMessage("postcode", "Invalid postcode");
        }
        if (!formData.name) {
            updateErrorMessage("name", "Invalid Name");
        }
        if (
            !formData.code ||
            !formData.name ||
            !formData.password ||
            !formData.phone ||
            !formData.address ||
            !formData.email ||
            !formData.postcode ||
            !validateSiteCode(formData.code) ||
            !validatePassword(formData.password) ||
            !validateEmail(formData.email)
        )
            return;

        if (props.edit === true) {
            mainStore.editSite(formData, props.siteInfo.id || "").then((res: any) => {
                if (res.success) {
                    props.onDrawerClose(false);
                } else {
                    AlertStore.show({
                        title: "Error",
                        message: res.message
                    });
                }
            });
        } else {
            mainStore.addSite(formData).then((res: any) => {
                if (res.success) {
                    props.onDrawerClose(false);
                } else {
                    AlertStore.show({
                        title: "Error",
                        message: res.message
                    });
                }
            });
        }
    };

    const updateAvatar = async (file: any) => {
        if (!file) {
            setFormData((pre: any) => ({ ...pre, logo: null }));
            return;
        }
        try {
            let url = await FirebaseApi.upload(file, null, () => { });
            setFormData((pre: any) => ({ ...pre, logo: url }));
        } catch (e) {
            console.log(e);
        }
    };

    const updateHomeVideo = async (file: any) => {
        if (!file) {
            setFormData((pre: any) => ({ ...pre, home_video_url: null }));
            return;
        }
        try {
            let url = await FirebaseApi.upload(file, null, () => { });
            setFormData((pre: any) => ({ ...pre, home_video_url: url }));
        } catch (e) {
            console.log(e);
        }
    };

    const onChangeValue = (e: any) => {
        let tname = e.target.name;
        let tvalue = e.target.value;
        const newData = { ...(formData || {}) } as any;
        if (tname === "lat" || tname === "lon") {
            newData.location = {
                ...(formData?.location || {}),
                [tname]: tvalue,
            };
        } else {
            newData[tname] = tvalue;
        }
        setFormData(newData);
        updateErrorMessage(e.target.name, "");
    };

    const onChangeCheckbox = (id: any) => {
        const newData = { ...(formData || {}) } as any;
        newData.services = {
            ...(formData?.services || {}),
            [id]: !(formData?.services || {})[id],
        };
        setFormData(newData);
    };

    const onCancel = () => {
        props.onDrawerClose(false);
    };

    const updateErrorMessage = (key: string, value: string) => {
        setErrorAlert((prev) => {
            return { ...prev, [key]: value };
        });
    };

    return props.isOpen ? (
        <Drawer
            open={props.isOpen}
            backdrop={true}
            position="right"
            size="sm"
            onClose={props.onDrawerClose}
        >
            <Container>
                <Typography variant="h4" ml={15} mb={15}>
                    {props.edit ? "Edit Site" : "Add Site"}
                </Typography>
                <div className="scroll-view">
                    <Row>
                        <UploadImagePicker
                            url={formData?.logo}
                            label="Avatar"
                            onChange={(url: string) => updateAvatar(url)}
                        />
                        <UploadVideoPicker
                            url={formData?.home_video_url}
                            label="Home Video"
                            onChange={(url: string) => updateHomeVideo(url)}
                        />
                    </Row>
                    <Row>
                        <InputBox
                            label="Site Code"
                            name="code"
                            value={formData?.code}
                            helpText={String(errorAlert.code)}
                            status={
                                errorAlert.code !== ""
                                    ? "error"
                                    : "normal"
                            }
                            onChange={onChangeValue}
                        />
                        <InputBox
                            label="Name"
                            name="name"
                            value={formData?.name}
                            helpText={errorAlert.name}
                            status={
                                errorAlert.name !== ""
                                    ? "error"
                                    : "normal"
                            }
                            onChange={onChangeValue}
                        />
                    </Row>
                    <Row>
                        <InputBox
                            label="Phone"
                            name="phone"
                            value={formData?.phone}
                            helpText={errorAlert.phone}
                            status={
                                errorAlert.phone !== ""
                                    ? "error"
                                    : "normal"
                            }
                            onChange={onChangeValue}
                        />
                        <InputBox
                            label="Email"
                            name="email"
                            type="email"
                            helpText={errorAlert.email}
                            status={
                                errorAlert.email !== ""
                                    ? "error"
                                    : "normal"
                            }
                            value={formData?.email}
                            onChange={onChangeValue}
                        />
                    </Row>
                    <Row>
                        <InputBox
                            label="Password"
                            name="password"
                            type="password"
                            value={formData?.password}
                            helpText={errorAlert.password}
                            status={
                                errorAlert.password !== ""
                                    ? "error"
                                    : "normal"
                            }
                            onChange={onChangeValue}
                        />
                        <InputBox
                            label="Address"
                            name="address"
                            value={formData?.address}
                            helpText={errorAlert.address}
                            status={
                                errorAlert.address !== ""
                                    ? "error"
                                    : "normal"
                            }
                            onChange={onChangeValue}
                        />
                    </Row>
                    <Row>
                        <InputBox
                            label="PostCode"
                            name="postcode"
                            value={formData?.postcode}
                            helpText={errorAlert.postcode}
                            status={
                                errorAlert.postcode !== ""
                                    ? "error"
                                    : "normal"
                            }
                            onChange={onChangeValue}
                        />
                        <InputBox
                            label="Website Url"
                            name="websiteUrl"
                            value={formData?.websiteUrl}
                            onChange={onChangeValue}
                        />
                    </Row>
                    <Row>
                        <InputBox
                            label="Booking Url"
                            name="bookingUrl"
                            value={formData?.bookingUrl}
                            onChange={onChangeValue}
                        />
                        <InputBox
                            label="Tutorial Video"
                            name="tutorialVideo"
                            value={formData?.tutorialVideo}
                            onChange={onChangeValue}
                        />
                    </Row>

                    <Row>
                        <InputBox
                            name="lat"
                            label="Lat"
                            type="text"
                            value={formData?.location?.lat}
                            onChange={onChangeValue}
                        />
                        <InputBox
                            name="lon"
                            label="Lon"
                            type="text"
                            value={formData?.location?.lon}
                            onChange={onChangeValue}
                        />
                    </Row>
                    <Row>
                        <InputBox
                            label="WAS Dealer ID"
                            name="wasDealerId"
                            value={formData?.wasDealerId}
                            onChange={onChangeValue}
                        />
                        <InputBox
                            label="Manager Name"
                            name="managerName"
                            value={formData?.managerName}
                            onChange={onChangeValue}
                        />
                    </Row>
                    <Row>
                        <InputBox
                            label="Spincar ID"
                            name="spincarId"
                            value={formData?.spincarId}
                            onChange={onChangeValue}
                        />
                    </Row>

                    <Typography variant="h5" mt={30} ml={10} display="block">
                        Directories
                    </Typography>
                    {Directories.map((services, index) => (
                        <div className="check-cover">
                            <CheckBox
                                key={index}
                                label={services.name}
                                checked={
                                    formData?.services?.[services.id]
                                        ? 1
                                        : 0
                                }
                                onClick={() =>
                                    onChangeCheckbox(services.id)
                                }
                            />
                        </div>
                    ))}
                </div>
                <div className="footer">
                    <Button
                        title="Cancel"
                        onClick={onCancel}
                        size="small"
                        icon="Back"
                        variant="secondary"
                        disabled={loading}
                    />
                    <Button
                        title={props.edit ? "Update" : "Add"}
                        onClick={onAdd}
                        size="small"
                        icon="SaveOne"
                        variant="primary"
                        disabled={loading}
                    />
                </div>
            </Container>
        </Drawer>
    ) : null;
};

export default observer(AddSite);

const Container = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 10px;
    box-sizing: border-box;

    .scroll-view {
        overflow: auto;
        flex: 1;
    }

    .footer {
        display: flex;
        justify-content: flex-end;
        gap: 20px;
        border-top: 1px solid ${ThemeColors['info-main']};
        padding: 10px 0;
    }

    .check-cover {
        display: inline-block;
        width: 50%;
    }
`;

const Row = styled.div`
    padding: 5px 10px;
    display: flex;
    gap: 10px;

    & > div {
        flex: 1;
    }
`;