import LeftMenu from "components/LeftMenu";
import { useEffect } from "react";
import { useParams } from "react-router";
import { Outlet } from "react-router";
import mainStore from "store/MainStore";
import { styled } from "styled-components";
import { observer } from "mobx-react";

const Layout = () => {
    const {companyId, siteCode} = useParams();
    useEffect(() => {
        if(companyId && siteCode) {
            mainStore.fetchMenus(companyId, siteCode);
            mainStore.fetchTheme(companyId, siteCode);
            mainStore.fetchIntegrations(companyId, siteCode);
            mainStore.fetchOnboarding(companyId, siteCode);
        }
        mainStore.fetchCompany();
        
    }, [companyId, siteCode]);
    return (
        <Container>
            <LeftMenu/>
            <Body>
                <Outlet />
            </Body>
        </Container>
    )
}

export default observer(Layout);

const Container = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
`;

const Body = styled.div`
    flex: 1;
    overflow:auto;
`;