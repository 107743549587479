export const validateEmail = (mail: string) => {
  if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(mail)) {
    return true;
  }
  return false;
};

export const validateCode = (code: string) => {
  if (code.length === 5) {
    return true;
  }
  return false;
};

export const validateSiteCode = (code: number | "") => {
  if (String(code).length === 7) {
    return true;
  }
  return false;
};

export const validatePassword = (password: any) => {
  if (password.length < 8) {
    return false;
  }
  let characterTypesCount = 0;
  // Check for uppercase letters
  if (/[A-Z]/.test(password)) {
    characterTypesCount++;
  }
  // Check for lowercase letters
  if (/[a-z]/.test(password)) {
    characterTypesCount++;
  }
  // Check for numerical characters
  if (/[0-9]/.test(password)) {
    characterTypesCount++;
  }
  // Check for special characters
  if (/[$@$!%*?&]/.test(password)) {
    characterTypesCount++;
  }
  // Check if at least 3 character types are present
  if (characterTypesCount < 3) {
    return false;
  }
  return true;
};
