import React from "react";
import { useState, useEffect } from "react";
import styled from "styled-components";
import { Typography, ThemeColors, Button, InputBox } from "awantunai-design-system";
import { IOnboard } from "interfaces";
import MainStore from "store/MainStore";
import { useParams } from "react-router";
import { useNavigate } from 'react-router-dom';
import { observer } from "mobx-react";
import { Container } from 'components/styleComponent/Container';
import UploadImagePicker from "components/general/UploadImagePicker";
import RadioButton from "components/general/RadioButton";
import { IOnChangeEvent } from "interfaces";
import FirebaseApi from "services/FirebaseApi";
import { runInAction, toJS } from "mobx";

const AddOnboarding = () => {
    const [visible, setVisible] = useState<boolean>(false);
    const [title, setTitle] = useState<string>('');
    const [description, setDescription] = useState<string>('');
    const [coverImg, setCoverImg] = useState<string>('');
    const [uploading, setUploading] = useState<boolean>(false);
    const { companyId, siteCode, iden } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        if (iden && iden === 'add') {
            setVisible(false);
            setTitle('');
            setDescription('');
            setCoverImg('');
        }
        if (iden && parseInt(iden) >= 0 && parseInt(iden) < MainStore.onboardings.cards.length) {
            const onBoardingEdit_data: IOnboard = MainStore.onboardings.cards[parseInt(iden)];
            setDescription(onBoardingEdit_data.description);
            setVisible(onBoardingEdit_data.visible);
            setTitle(onBoardingEdit_data.title);
            setCoverImg(onBoardingEdit_data.image);
        }
    }, [iden]);

    /*********** Update logic of the site onboarding **************/
    const onUpdate = () => {
        MainStore.onboardings.visible = true;
        if (iden && iden !== "add") {
            MainStore.onboardings.cards[parseInt(iden)] = {
                ...MainStore.onboardings.cards[parseInt(iden)],
                title: title,
                visible: visible,
                description: description,
                image: coverImg
            }
        }
        if (companyId && siteCode) {
            MainStore.createOnboarding(toJS(MainStore.onboardings), companyId, siteCode);
        }
        navigate(`/${companyId}/${siteCode}/onboarding`, { replace: true });
    };

    /**********  Add logic of the site onboarding *********/
    const onSave = () => {
        const newOnboarding = [...(MainStore.onboardings.cards || [])];
        newOnboarding.push({
            title: title,
            description: description,
            image: coverImg,
            visible: visible,
        });
        runInAction(() => {
            MainStore.onboardings.cards = newOnboarding;
        });
        MainStore.onboardings.visible = true;
        if (companyId && siteCode) {
            MainStore.createOnboarding(toJS(MainStore.onboardings), companyId, siteCode);
        }
        navigate(`/${companyId}/${siteCode}/onboarding`, { replace: true });
    };

    const handleChangeRadio = (option: any) => {
        setVisible(option.value);
    }
    const onChangeCoverImg = async (file: any) => {
        if (file === null) {
            setCoverImg(null as any);
            return;
        }
        let url = await FirebaseApi.upload(file, null, (e) => {
            if (e) {
                setUploading(true);
            }
        });
        setUploading(false)
        setCoverImg(url);
    };

    return (
        <Container>
            <Typography variant="h4" mb={15}>Site Onboarding {iden === "add" ? "Add page" : "Edit page"}</Typography>
            <Row>
                <div className="mt-3 col">
                    <CardContainer>
                        <DescBox>
                            <Typography mt={10} variant="subtitle2" style={{ overflowWrap: 'anywhere' }}>{title}</Typography>
                            <Typography mt={10} variant="body3" style={{ overflowWrap: 'anywhere' }}>{description}</Typography>
                        </DescBox>
                        <CardImg src={coverImg} />
                        <Button
                            size="small"
                            style={{
                                width: 30,
                                height: 30,
                                borderRadius: 20,
                                backgroundColor: visible ? '#52dfbd' : '#d9d9d8',
                                position: "absolute",
                                bottom: 20,
                                boxShadow: '0 2px 6px 0 rgba(116,120,141,1.5)'
                            }}
                        />
                    </CardContainer>
                </div>
                <div className="mt-3 col">
                    <div style={{ position: 'relative' }}>
                        <UploadImagePicker
                            label={"Image"}
                            url={coverImg}
                            onChange={(file: any) => {
                                onChangeCoverImg(file)
                            }}
                            uploading={uploading}
                        />
                    </div>
                    <div style={{ marginTop: '20px' }}>
                        <InputBox
                            label="Title"
                            name='title'
                            value={title}
                            onChange={(e: IOnChangeEvent) => { setTitle(e.target.value) }}
                        />
                    </div>
                    <div style={{ marginTop: '20px' }}>
                        <InputBox
                            label="Description"
                            name='description'
                            value={description}
                            variant="textarea"
                            onChange={(e: IOnChangeEvent) => { setDescription(e.target.value) }}
                        />
                    </div>
                    <Visible>
                        <span className="label">
                            <Typography variant="h6" mb={15}>Visible</Typography>
                        </span>
                        <RadioButton
                            options={[{ value: true, label: 'Yes' }, { value: false, label: 'No' }]}
                            value={visible}
                            direction="row"
                            onChange={(option: any) => { handleChangeRadio(option) }}
                        />
                    </Visible>
                    <CreateButton>
                        <Button
                            title="Save"
                            onClick={iden === 'add' ? onSave : onUpdate}
                            size="small"
                            icon="SaveOne"
                            variant="primary"
                            style={{ width: 100, marginTop: 20 }}
                            disabled={uploading}
                        />
                    </CreateButton>
                </div>
            </Row>
        </Container>
    )
};

export default observer(AddOnboarding);

const Row = styled.div`
    --bs-gutter-x: 20px;
    --bs-gutter-y: 0;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    padding:25px;
    margin-top: calc(var(--bs-gutter-y)*-1);
    margin-right: calc(var(--bs-gutter-x)*-0.5);
    margin-left: calc(var(--bs-gutter-x)*-0.5);

    .row>* {
        position: relative;
        width: 100%;
        max-width: 100%;
        padding-right: calc(var(--bs-gutter-x)*0.5);
        padding-left: calc(var(--bs-gutter-x)*0.5);
    }
    .mt-3 {
        margin-top: 1rem!important;
        padding-right:30px;
    }
    .col {
        flex: 1 0;
    }
    .width-right {
        width:50%;
    }
`;

const CardContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 300px;
    height: 455px;
    border-radius: 20px;
    border-color: ${ThemeColors.main};
    border: solid;
    border-width: 1px;
    position: relative;
    background: ${ThemeColors.white};
    overflow: hidden;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    padding: 10px;
    margin-left: 20px;
`

const DescBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 100px;
`

const CardImg = styled.img`
    width: 100%;
    height: 200px;
    border-radius: 20px;
    object-fit: cover;
    margin-top: 20px;
`

const Visible = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top:10px;
`

const CreateButton = styled.div`
    margin-top:10px;
    display: flex;
    flex-direction: row;
    justify-content: end;
`