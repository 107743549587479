import { ThemeColors, Typography } from 'awantunai-design-system';
import { RotatingLines } from 'react-loader-spinner';
import styled from "styled-components";


interface LoadingProps {
  isloading: boolean;
}

const Loading = ({
  isloading,
}: LoadingProps) => {

  return isloading ? (
    <Container>
      <div className='div'>
        <RotatingLines
          strokeColor={ThemeColors['success-main']}
          strokeWidth="5"
          animationDuration="0.75"
          width="30"
          visible={true}
        />
        <Typography variant="body2" color='secondary-dark' ml={10}>Loading...</Typography>
      </div>
    </Container>
  ) : null
}

export default Loading;

const Container = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);

    display: flex;
    justify-content: center;
    align-items: center;

    .div {
        width: 100px;
        height: 100px;
        background-color: white;
        border-radius: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        gap: 10px;
    }
`;