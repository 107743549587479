import React from "react";
import styled from "styled-components";

const CarouselPage = () => {
  return (
    <React.Fragment>
        <Container>
            
            <div className="col-xxl-9 col-lg-8 col-md-7">
            <div className="auth-bg pt-md-5 p-4 d-flex">
            <img src="" alt="" />
            <div className="bg-overlay bg-blue"></div>
            <ul className="bg-bubbles">
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
            </ul>
            <div className="row justify-content-center align-items-center">
                <div className="col-xl-7">
                <div className="p-0 p-sm-4 px-xl-0">
                    <div
                        id="reviewcarouselIndicators"
                        className="carousel slide"
                        data-bs-ride="carousel"
                    >
                    </div>
                </div>
                </div>
            </div>
            </div>
        </div>
        </Container>
    </React.Fragment>
  );
};

export default CarouselPage;
const Container = styled.div`
.auth-bg {
    background-image: url("https://hendy-production.web.app/static/media/bg.5076ac06.jpg");
    background-position: 50%;
    height:100vh;
    width:67vw;
    opacity:0.2;
    background-size: cover;
    background-repeat: no-repeat;
    .bg-overlay {
      opacity: 0.9;
    }
    @media (min-width: 768px) {
      height: 100vh;
    }
    .bg-blue {
      background-color: $blue;
    }
}

  @media (max-width:769px) {
      .auth-bg {
          display:none;
      }
  }
  
  .auth-full-page-content {
    min-height: 100vh;
  }
  
  // auth 2
  
  .auth-logo {
    .logo-txt {
      color: $dark;
      font-size: 20px;
    }
  }
  .bg-bubbles {
    position: relative;
    top: 0;
    left: 0;
    width: 95%;
    height: 95%;
    overflow: hidden;
    li {
      position: absolute;
      list-style: none;
      display: block;
      width: 40px;
      height: 40px;
      border-radius: 30px;
      background-color: rgb(255 255 255 / 49%);
      bottom: -50px;
      animation: square 20s infinite;
      transition-timing-function: linear;
      &:nth-child(1) {
        left: 10%;
      }
      &:nth-child(2) {
        left: 20%;
        width: 120px;
        height: 120px;
        animation-delay: 2s;
        animation-duration: 17s;
      }
      &:nth-child(3) {
        left: 25%;
        animation-delay: 4s;
      }
      &:nth-child(4) {
        left: 40%;
        width: 80px;
        height: 80px;
        animation-duration: 22s;
      }
      &:nth-child(5) {
        left: 70%;
        width: 90px;
        height: 90px;
      }
      &:nth-child(6) {
        left: 70%;
        width: 120px;
        height: 120px;
        animation-delay: 3s;
      }
      &:nth-child(7) {
        left: 32%;
        width: 150px;
        height: 150px;
        animation-delay: 7s;
      }
      &:nth-child(8) {
        left: 55%;
        width: 80px;
        height: 80px;
        animation-delay: 15s;
        animation-duration: 40s;
      }
      &:nth-child(9) {
        left: 25%;
        width: 50px;
        height: 50px;
        animation-delay: 2s;
        animation-duration: 40s;
      }
      &:nth-child(10) {
        left: 90%;
        width: 140px;
        height: 140px;
        animation-delay: 11s;
      }
    }
  }
  @keyframes square {
    0% {
      transform: translateY(0);
    }
    100% {
      transform: translateY(-1000px) rotate(600deg);
    }
  }
  
  body[data-layout-mode="dark"] {
    .auth-logo {
      .logo-txt {
        color: $white;
      }
    }
  }
`


