import { Route, BrowserRouter, Routes, Navigate } from "react-router-dom";
import styled from "styled-components";

// Auth View
import AuthLayout from "./AuthLayout";
import LoginView from "pages/auth/LoginView";
import ForgotPasswordView from "pages/auth/ForgotPasswordView";

// Main View
import Layout from "./Layout";
import ThemeView from "pages/main/ThemeView";
import HomeMenuView from "pages/main/HomeMenuView";
import LeftMenuView from "pages/main/LeftMenuView";
import SiteList from "pages/company/SiteListView";

// Error View
import authStore from "store/AuthStore";
import { observer } from "mobx-react";
import Integration from "pages/main/IntegrationView";
import Onboarding from "pages/main/Onboarding/OnboardingView";
import AddOnboarding from "pages/main/Onboarding/AddOnboarding";
import CompanyEditView from "pages/company/CompanyEditView";
import ConfirmModal from "components/general/ConfirmModal";
import ClaimView from "pages/main/ClaimView";

const Router = () => {

    return (
        <Container>
            <BrowserRouter>
                <Routes>
                    {!authStore.isLoggedIn ?
                        (<Route element={<AuthLayout />}>
                            <Route path="login" element={<LoginView />} />
                            <Route path="forgot-password" element={<ForgotPasswordView />} />
                            <Route path="*" element={<Navigate to="login" replace />} />
                        </Route>) : <>
                            <Route path="/:companyId/:siteCode" element={<Layout />}>
                                <Route path="/:companyId/:siteCode/theme" element={<ThemeView />} />
                                <Route path="/:companyId/:siteCode/home-menu" element={<HomeMenuView />} />
                                <Route path="/:companyId/:siteCode/left-menu" element={<LeftMenuView />} />
                                <Route path="/:companyId/:siteCode/integration" element={<Integration />} />
                                <Route path="/:companyId/:siteCode/onboarding" element={<Onboarding />} />
                                <Route path="/:companyId/:siteCode/claims" element={<ClaimView />} />
                                <Route path="/:companyId/:siteCode/onboarding/edit/:iden" element={<AddOnboarding />} />
                                <Route path="*" element={<Navigate to=":companyId/theme" replace />} />
                            </Route>
                            <Route path="/" element={<Layout />}>
                                <Route path="company/edit/:id" element={<CompanyEditView />} />
                                <Route path="company/:id" element={<SiteList />} />
                                <Route path="*" element={<Navigate to="/company:id" replace />} />
                            </Route>
                        </>
                    }
                </Routes>
            </BrowserRouter>
            <ConfirmModal />
        </Container>
    )
}

export default observer(Router);

const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
`;
