import React, { useState } from "react";
import { Typography, ThemeColors, Button, } from 'awantunai-design-system';
import { styled } from "styled-components";
import Accordion from '../../components/general/Accordion';
import { observer } from "mobx-react";
import mainStore from 'store/MainStore';
import { useParams } from "react-router";
import DynamicInput from '../../components/general/DynamicInput';
import AddPropertyModal from '../../components/integration/AddPropertyModal';
import Loading from "components/general/Loading";
import AddIntegrationModal from "components/integration/AddIntegrationModal";

const Integration = () => {

    const { companyId, siteCode } = useParams();
    const loading = mainStore.loading;
    const [showModal, setShowModal] = useState<boolean>(false);
    const [showNewModal, setShowNewModal] = useState(false);

    const onSave = () => {
        if (companyId && siteCode) {
            mainStore.updateIntegration(companyId, siteCode);
        }
    };

    const onChangeIntegration = (e: any, index: number, ind: number) => {
        mainStore.integrations[index].extra[ind] = {
            ...mainStore.integrations[index].extra[ind],
            [e.target.name]: e.target.value
        }
    }

    const onDeleteFormField = (index: number, ind: number) => {
        const newFormField = [...mainStore.integrations[index].extra];
        let filterResult = newFormField.filter((form, i) => i !== ind);
        mainStore.integrations[index].extra = filterResult;
    };

    const onExportFile = () => {
        const data = JSON.stringify(mainStore.integrations);
        const blob = new Blob([data], { type: "application/json" });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.setAttribute("href", url);
        a.setAttribute("download", `${companyId}_${siteCode}_thirdparty_manager.json`);
        a.click();
    }

    const onImportFile = () => {
        const input = document.createElement("input");
        input.type = "file";
        input.accept = ".json";
        input.onchange = async (e: any) => {
            const file = e.target.files[0];
            if (file) {
                const text = await file.text();
                const data = JSON.parse(text);
                mainStore.integrations = data;
            }
        }
        input.click();
    }

    const onAdd = () => {
        setShowNewModal(true);
    }

    return (
        <Container>
            <Typography variant="h4" mb={15}>Integration</Typography>
            <div className="scroll-view">
                <div className={"section"}>
                    {mainStore.integrations ? (mainStore.integrations.map((item: any, index: number) =>
                        <Accordion key={index} label={item.serviceName}>
                            {item.extra.map((extra: any, ind: number) => (
                                <div key={ind} className="item">
                                    <DynamicInput
                                        name={extra.key}
                                        {...extra}
                                        onChange={(value: any) => onChangeIntegration(value, index, ind)}
                                    />
                                    <Button
                                        icon="Delete"
                                        variant="secondary"
                                        size="small"
                                        onClick={() => onDeleteFormField(index, ind)}
                                    />
                                </div>
                            ))}
                            <Button
                                icon="Plus"
                                title="Add"
                                variant="primary"
                                size="small"
                                onClick={() => setShowModal(true)}
                            />
                            <AddPropertyModal onClick={() => { setShowModal(false); }} id={index} isOpen={showModal} />
                        </Accordion>
                    )) : (<><span>No datas</span></>)}
                    <Button
                        title="Add"
                        onClick={onAdd}
                        size="small"
                        icon="AddOne"
                        variant="secondary"
                    />
                </div>
            </div>
            <AddIntegrationModal
                visible={showNewModal}
                onClose={() => setShowNewModal(false)}
            />
            <Loading isloading={loading} />
            <div className='footer'>
                <Button
                    title="Save"
                    onClick={onSave}
                    size="small"
                    icon="SaveOne"
                    variant="primary"
                />
                <Button
                    title="Import File"
                    onClick={onImportFile}
                    size="small"
                    icon="Upload"
                    variant="primary"
                />
                <Button
                    title="Export File"
                    onClick={onExportFile}
                    size="small"
                    icon="Download"
                    variant="primary"
                />
            </div>
        </Container>
    )

}

export default observer(Integration);

const Container = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 20px;
    box-sizing: border-box;
    

    .scroll-view {
        overflow:auto;
        flex: 1;
        background-color: ${ThemeColors["info-light"]};
    }

    .section {
        
        border-radius: 5px;
        padding: 20px;
        margin: 10px 0;
    }
    
    .item {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 10px;
        border-bottom: 1px solid ${ThemeColors['info-main']};
        padding-bottom: 10px;

        & > div {
            width: 300px;
        }
    }

    .footer {
        display: flex;
        margin-top: 20px;
        gap: 20px;
    }
`;