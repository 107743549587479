import { Button, Icons, InputBox, TabBar, ThemeColors, Typography } from "awantunai-design-system";
import Loading from "components/general/Loading";
import { observer } from "mobx-react";
import moment from "moment";
import { useEffect, useState } from "react";
// @ts-ignore
import InfiniteScroll from "react-infinite-scroller";
import { useParams } from "react-router";
import mainStore from "store/MainStore";
import styled from "styled-components";


const ClaimView = () => {

    const { companyId, siteCode } = useParams();
    const [selectedIndex, setSelectedIndex] = useState<number>(-1);
    const [customer, setCustomer] = useState<any>(null);
    const [notes, setNotes] = useState<string>('');
    const [tabIndex, setTabIndex] = useState<number>(0);

    const IconDown = Icons['Down'];
    const IconUp = Icons['Up'];

    const onChangeTab = (index: number) => {
        setTabIndex(index);
    }

    const loadFunc = (page: number) => {
        console.log("loadFunc", page);
    };

    const onSelect = async (index: number) => {
        if (selectedIndex === index) {
            setSelectedIndex(-1);
            setCustomer(null);
        } else {
            setSelectedIndex(index);
            const customer = await mainStore.getCustomerById(mainStore.claims[index].customerId);
            setCustomer(customer);
        }
    };

    const onMark = (claimId: string) => {
        mainStore.markClaim(claimId);
    }

    const onSendMessage = (claimId: string) => {
        mainStore.sendMessage(claimId, notes);
    }

    useEffect(() => {
        if (companyId && siteCode) {
            mainStore.fetchClaimsBySiteCode(companyId, siteCode);
        }
    }, [companyId, siteCode]);

    return (
        <Container>
            <Typography variant="h4" mb={15}>Claims</Typography>
            <div className="scroll-view">
                <div className={"section"}>
                    <TabBar
                        activeIndex={tabIndex}
                        onChange={onChangeTab}
                        tabs={[
                            {
                                label: 'Unresolved'
                            },
                            {
                                label: 'All'
                            }
                        ]}
                    >
                        <>  </>
                    </TabBar>
                    <InfiniteScroll
                        pageStart={0}
                        loadMore={loadFunc}
                        hasMore={true || false}
                        // loader={<div className="loader" key={0}>Loading ...</div>}
                        useWindow={false}
                    >
                        {mainStore.claims.filter(claims => tabIndex === 1 || (tabIndex === 0 && !claims.resolved))
                            .map((claim, index) => (
                                <div key={index} className="claim-item">
                                    <div className="item-body" onClick={() => onSelect(index)}>
                                        <Typography variant="h5">{claim.regNo}</Typography>
                                        <Typography variant="body2">{claim.type}</Typography>
                                        <Typography variant="body2">{moment(claim.createdAt).fromNow()}</Typography>
                                        <div className="status" style={{ backgroundColor: claim?.resolved ? ThemeColors["primary-dark"] : ThemeColors["error-dark"] }}>
                                            <Typography variant="body2" color="secondary-light">{claim.resolved ? "Resolved" : "Pending"}</Typography>
                                        </div>
                                        {selectedIndex === index ? <IconUp
                                            theme="two-tone"
                                            fill={[ThemeColors['primary-main'], 'transparnet']}
                                            size={26}
                                            style={{ height: 26 }}
                                        /> : <IconDown
                                            theme="two-tone"
                                            fill={[ThemeColors['primary-main'], 'transparnet']}
                                            size={26}
                                            style={{ height: 26 }}

                                        />}
                                    </div>
                                    {selectedIndex === index && (
                                        <div className="detail-view">
                                            <div className="notes-section">
                                                <Typography display="block" variant="h6">Notes</Typography>
                                                <Typography display="block" variant="body2" mt={5} ml={5}>{claim.notes}</Typography>
                                            </div>
                                            <div className="assets-section">
                                                <Typography display="block" variant="h6" mb={5}>Assets</Typography>
                                                <div>
                                                    {claim.attachments.map((asset, index) => (
                                                        asset.type === 'image' ? <img key={index} className="asset-item" src={asset.file} alt={asset.type} />
                                                            : <video key={index} className="asset-item" src={asset.file} controls />
                                                    ))}
                                                </div>
                                            </div>
                                            <div className="customer-section">
                                                <Typography display="block" variant="h6">Customer Info</Typography>
                                                <Typography display="block" variant="body2" mt={5}>Customer Name: {customer?.fristName + " " + customer?.lastName}</Typography>
                                                <Typography display="block" variant="body2" mt={5}>Customer Number: {customer?.phone}</Typography>
                                            </div>
                                            <InputBox
                                                helpText="Write a message to customer"
                                                label="Message to customer"
                                                variant="textarea"
                                                value={notes}
                                                onChange={(e) => setNotes(e.target.value)}
                                            />
                                            <div className="item-action">
                                                <Button
                                                    variant="primary"
                                                    size="small"
                                                    title={claim.resolved ? "Mark as unresolved" : "Mark as resolved"}
                                                    onClick={() => onMark(claim.id)}
                                                />
                                                <Button
                                                    variant="primary"
                                                    size="small"
                                                    title="Send message"
                                                    onClick={() => onSendMessage(claim.id)}
                                                />
                                            </div>
                                        </div>
                                    )}
                                </div>
                            ))}
                    </InfiniteScroll>
                </div>
            </div>
            <Loading isloading={mainStore.loading} />
        </Container >
    );
}

export default observer(ClaimView);


const Container = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 20px;
    box-sizing: border-box;
    

    .scroll-view {
        overflow:auto;
        flex: 1;
        background-color: ${ThemeColors["info-light"]};

        .tab-bar {
            height: unset;
        }
    }

    .section {
        
        border-radius: 5px;
        padding: 20px;
        margin: 10px 0;
    }

    .claim-item {
        background-color: white;
        border-radius: 5px;
        padding: 20px;
        margin: 10px 0;

        .item-body {
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .status {
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 5px 10px;
                border-radius: 5px;
            }
        }
    }

    .detail-view {
        background-color: white;
        padding: 20px;
        margin: 10px 0;
        border-top: 1px solid ${ThemeColors["info-dark"]};

        .assets-section {
            margin: 20px 0;

            .asset-item {
                width: 200px;
                height: 150px;
                margin: 10px;
                object-fit: cover;
                border: 1px solid ${ThemeColors["info-dark"]};
                display: inline-flex;
            }
        }

        .customer-section {
            margin: 20px 0;
        }

        .item-action {
            display: flex;
            gap: 10px;
            margin-top: 10px;
            justify-content: flex-end;
        }
    }

`;