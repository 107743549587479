import React, { useEffect } from "react";
import { Button, ThemeColors, Typography } from "awantunai-design-system";
import styled from "styled-components";
import mainStore from "store/MainStore";
import { observer } from "mobx-react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import AlertStore from "store/AlertStore";

const tableColumns = [
    { key: "logo", label: "Logo" },
    { key: "companyCode", label: "Company Code" },
    { key: "code", label: "Site Code" },
    { key: "address", label: "Address" },
    { key: "name", label: "Name" },
    { key: "phone", label: "Phone" },
    { key: "email", label: "Email" },
    { key: "action", label: "Action" },
];

const TablePage = (props: any) => {
    const { id } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        if (id) {
            mainStore.fetchSites(id);
        }
    }, [id]);

    const sites = mainStore.siteLists;
    const site_data = [{ companyCode: id, code: "default" }, ...sites];

    const onEdit = (index: number, companyId: string, siteCode: string) => {
        navigate(`/${companyId}/${siteCode}/theme`, { replace: true });
    };

    const onSiteEdit = (e: any, index: number, siteInfo: any) => {
        e.stopPropagation();
        props.onSetSiteCode(siteInfo);
        props.onChangeEdit(true);
        props.onDrawerClose(true);
    };

    const onDelete = (e: any, siteId: string) => {
        e.stopPropagation();
        AlertStore.show({
            title: "Delete Site",
            message: "Are you sure you want to delete this site?",
            onConfirm: async () => {
                mainStore.deleteSite(siteId);
            },
        });
    };

    return (
        <Container>
            <div
                style={{ overflow: "auto", height: "86vh" }}
                className="tablestyle"
            >
                <table>
                    <thead>
                        <tr>
                            {tableColumns.map((column, index) => (
                                <th key={index}>
                                    <Typography variant="subtitle3">
                                        {column.label}
                                    </Typography>
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {site_data.length > 0 &&
                            site_data.map((item: any, index: number) => (
                                <tr key={index} onClick={() => onEdit(index, item.companyCode, item.code)}>
                                    {tableColumns.map((column, colIndex) => (
                                        <td key={colIndex}>
                                            {column.key === 'logo' ?
                                                <img
                                                    alt="logo"
                                                    src={item.logo}
                                                    className="img"
                                                /> :
                                                column.key === 'action' ? <div className="row">
                                                    <Button
                                                        icon="Edit"
                                                        variant="secondary"
                                                        size="small"
                                                        disabled={item.code === "default"}
                                                        onClick={(e) =>
                                                            onSiteEdit(e, index, item)
                                                        }
                                                    />
                                                    <Button
                                                        icon="Delete"
                                                        variant="secondary"
                                                        size="small"
                                                        disabled={item.code === "default"}
                                                        onClick={(e) =>
                                                            onDelete(e, item.id)
                                                        }
                                                    />
                                                </div> :
                                                    <Typography variant="body2">
                                                        {item[column.key]}
                                                    </Typography>
                                            }
                                        </td>
                                    ))}
                                </tr>
                            ))}
                    </tbody>
                </table>
            </div>
        </Container>
    );
};

export default observer(TablePage);

const Container = styled.div`
    .tablestyle {
        padding: 40px;
    }
    table {
        border-collapse: collapse;
        width: 100%;
        font-family: "Open Sans";
    }

    th,
    td {
        text-align: left;
        padding: 8px;
    }
    .row {
        display: flex;
    }
    tr:hover {
        background-color: ${ThemeColors["info-light"]};
    }
    tr {
        border-bottom: 1px solid;
        border-bottom-color: ${ThemeColors["info-main"]};
        cursor: pointer;
    }
    .img {
        width: 40px;
        height: 40px;
        border-radius: 50% !important;
        background-color: ${ThemeColors["info-main"]};
    }
    .btnGroupHeader {
        width: 100px;
    }
    .btnGroup {
        display: flex;
        width: 100px;
    }
    button {
        margin-right: 10px;
    }
`;
