import styled from 'styled-components';
import { ThemeColors, Typography } from "awantunai-design-system";

interface RadioType {
    options: Array<{ label: string, value: any }>,
    value: any,
    direction: string,
    onChange: (e: any) => void;
}
const RadioButton = ({
    options, // Array<{label: string, value: any}>
    value,
    onChange,
    direction, //row | column
}: RadioType) => {

    const onClickOption = (option: any) => {
        if (onChange) {
            onChange(option);
        }
    }

    return (
        <Container direction={direction} tabIndex={0}>
            {options.map((option, index) => (
                <div key={index} className='option' onClick={() => onClickOption(option)}>
                    <div className={'dot' + (option.value === value ? ' selected' : '')}>
                        <div className='small-dot' />
                    </div>
                    <Typography variant="body2" >{option.label}</Typography>
                </div>
            ))}
        </Container>
    )
}

export default RadioButton;


const Container = styled.div<{ direction: string }>`
    display: flex;
    flex-direction: ${({ direction }) => direction};

    .option {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin: 12px;

        .dot {
            display: flex;
            width: 20px;
            height: 20px;
            background-color: white;
            border-radius: 10px;
            align-items: center;
            justify-content: center;
            border: solid 1px ${ThemeColors["info-dark"]};
            margin-right: 12px;
            cursor: pointer;
            box-sizing: border-box;

            .small-dot {
                display: none;
                width: 12px;
                height: 12px;
                border-radius: 6px;
                background-color: ${ThemeColors["primary-main"]};;
            }
        }
        .selected {
            border-color: ${ThemeColors["primary-main"]};
            .small-dot {
                display: block;
            }
        }
    }
`