import { ThemeColors } from 'awantunai-design-system';
import styled from 'styled-components';

export const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 20px;
  box-sizing: border-box;

  .section {
    background-color: ${ThemeColors["info-light"]};
    border-radius: 5px;
    padding: 20px;
    margin: 10px 0;
  }

  .scroll-view {
    overflow:auto;
    flex: 1;
  }
`;
