import { BaseModal, Typography } from "awantunai-design-system"
import { observer } from "mobx-react"
import AlertStore from "store/AlertStore"
import { styled } from "styled-components"





const ConfirmModal = () => {

    const {
        title,
        isOpen,
        message,
        onConfirm,
        onCancel,
        confirmText,
        cancelText,
        width
    } = AlertStore

    const onClose = () => {
        AlertStore.hide();
        onCancel?.()
    }

    const _onConfirm = () => {
        AlertStore.hide();
        onConfirm?.()
    }

    return (
        <BaseModal
            title={title}
            buttons={[
                {
                    title: cancelText,
                    onClick: onClose,
                    // @ts-ignore
                    size: 'small'
                },
                {
                    title: confirmText,
                    onClick: _onConfirm,
                    // @ts-ignore
                    size: 'small'
                }
            ]}
            size={width}
            onClose={onClose}
            isOpen={isOpen}
        >
            <Container>
                <Typography variant="body2">{message}</Typography>
            </Container>
        </BaseModal>
    )
}

export default observer(ConfirmModal);

const Container = styled.div`
    padding: 12px 24px;
    min-height: 80px;
`