import styled from "styled-components";
import React, { useState } from "react";
import { Typography, InputBox } from 'awantunai-design-system';
import { IOnChangeEvent } from "interfaces";
import RadioButton from "components/general/RadioButton";
import FirebaseApi from "../../services/FirebaseApi";
import { IExtra } from "interfaces";
import UploadImagePicker from "./UploadImagePicker";

interface IDynamicInput extends IExtra {
    onChange: (value: IOnChangeEvent) => void;
    name: string;
}

const DynamicInput = (props: IDynamicInput) => {
    const [uploading, setUploading] = useState<boolean>(false);

    const handleChangeRadio = (option: any) => {
        props.onChange({
            target: {
                name: 'value',
                value: option.value
            }
        })
    }

    const handleChangeFile = async (file: any) => {
        if (file === null) {
            props.onChange({
                target: {
                    name: 'value'
                }
            } as any)
            return;
        }
        let url = await FirebaseApi.upload(file, null, (e) => {
            if (e) {
                setUploading(true);
            }
        });
        setUploading(false);
        props.onChange({
            target: {
                name: 'value',
                value: url
            }
        })
    }

    return (
        <Container>
            {props.inputType === "string" &&
                <InputBox
                    label={props.name}
                    name='value'
                    value={props.value}
                    onChange={props.onChange}
                />
            }
            {props.inputType === "bool" &&
                <>
                    <Typography variant="subtitle3">{props.name}</Typography>
                    <RadioButton
                        options={[{ value: 'true', label: 'Yes' }, { value: 'false', label: 'No' }]}
                        value={props.value}
                        direction="row"
                        onChange={handleChangeRadio}
                    />
                </>
            }
            {props.inputType === "file" &&
                <UploadImagePicker
                    label={props.name}
                    url={props.value}
                    onChange={(file: any) => {
                        handleChangeFile(file)
                    }}
                    uploading={uploading}
                    style={{ height: 120, width: 300 }}
                />
            }
        </Container >
    )
}

export default DynamicInput;

const Container = styled.div`
    display: flex;
    flex-direction: column;
`;