import { Button, Typography, Icons, ThemeColors } from "awantunai-design-system";
import { observer } from "mobx-react";
import { Container } from 'components/styleComponent/Container';
import styled from "styled-components";
import { IOnboard } from "interfaces";
import MainStore from "store/MainStore";
import OnboardingCard from "components/onboarding/OnboardingCard";
import { useParams } from "react-router";
import { useNavigate } from 'react-router-dom';
import { toJS } from "mobx";
import Loading from "components/general/Loading";

const Onboarding = () => {
    const { companyId, siteCode } = useParams();
    const navigate = useNavigate();
    const loading = MainStore.loading;
    const Icon = Icons['Add'];

    const onChangeVisible = () => {
        console.log("onchangevisible");
    }

    const onEdit = (index: number) => {
        navigate(`/${companyId}/${siteCode}/onboarding/edit/${index}`, { replace: true });
    }

    const onRemove = (e: IOnboard) => {
        const shouldDelete = window.confirm("Are you sure you want to delete?");
        if (shouldDelete) {
            const index = MainStore.onboardings.cards.findIndex(card => card === e);
            MainStore.onboardings.cards.splice(index, 1);
            MainStore.onboardings.visible = true;
        }
        if (companyId && siteCode) {
            MainStore.createOnboarding(toJS(MainStore.onboardings), companyId, siteCode);
        }

    }

    const onAddNew = () => {
        navigate(`/${companyId}/${siteCode}/onboarding/edit/add`, { replace: true });
    };

    const onExportFile = () => {
        const data = JSON.stringify(MainStore.onboardings);
        const blob = new Blob([data], {type: "application/json"});
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.setAttribute("href", url);
        a.setAttribute("download", `${companyId}_${siteCode}_onboarding.json`);
        a.click();
    }

    const onImportFile = () => {
        const input = document.createElement("input");
        input.type = "file";
        input.accept = ".json";
        input.onchange = async (e: any) => {
            const file = e.target.files[0];
            if (file) {
                const text = await file.text();
                const data = JSON.parse(text);
                MainStore.onboardings = data;
                if (companyId && siteCode) {
                    MainStore.createOnboarding(toJS(MainStore.onboardings), companyId, siteCode);
                }
            }
        }
        input.click();
    }

    return (
        <Container>
            <Typography variant="h4" mb={15}>Onboarding</Typography>
            <div className="scroll-view">
                <div className={"section"}>
                    <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                        {MainStore.onboardings.cards?.map((card: any, index: number) => (
                            <OnboardingCard
                                key={index}
                                onChange={onChangeVisible}
                                onEdit={() => onEdit(index)}
                                onRemove={onRemove}
                                onboarding={card}
                            />
                        ))}
                        <CardContainer>
                            <Icon
                                theme="two-tone"
                                fill={[ThemeColors['primary-main'], 'transparnet']}
                                size={26}
                                style={{ height: 26 }}
                                onClick={onAddNew}
                            />
                            <Typography variant="h5" mt={10}>Add New</Typography>
                        </CardContainer>
                    </div>
                </div>
            </div>
            <Loading isloading={loading} />
            <div>
                <Button
                    title="Import File"
                    onClick={onImportFile}
                    size="small"
                    icon="Upload"
                    variant="primary"
                    style={loading ? { display: 'none' } : { width: 120, marginLeft: 20, marginTop: 20, display: "inline-flex" }}
                />
                <Button
                    title="Export File"
                    onClick={onExportFile}
                    size="small"
                    icon="Download"
                    variant="primary"
                    style={loading ? { display: 'none' } : { width: 120, marginLeft: 20, marginTop: 20, display: "inline-flex" }}
                />
            </div>
        </Container>
    );
};

export default observer(Onboarding);

const CardContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 230px;
    height: 360px;
    border-radius: 20px;
    border: solid 1px ${ThemeColors.main};
    background: ${ThemeColors.white};
    overflow: hidden;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    padding: 20px;
    margin-left: 10px;
    margin-top: 10px;
    cursor: pointer;
`
